import React, { Fragment, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { browserHistory } from 'react-router';
import moment from 'moment';
import { Button, Accordion, AccordionDetails, AccordionSummary, Paper, Grid, Table, TableBody, TableRow, TableCell, Tooltip } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import EditIcon from '@material-ui/icons/Edit';
import InfoIcon from '@material-ui/icons/Info';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import { FormT012, WorkOrderHd, AssignEngineer, FormList, ProjectDetailTab, ProjectViewsSkeleton, AircraftCRU, ALPCRU, ProjectUsers, WorkOrderDropdown, AssignOprEngineer, AddEngineWorkOrder } from '../components';
import { DeletePopUp, PageLoader } from '../../../shared_elements';
import { globalGetService, globalPostService, globalDeleteService } from '../../../utils_v2/globalApiServices'
import { checkApiStatus, getLocalStorageInfo, onCheckPermission } from '../../../utils_v2'
import { displayDateFormatShort } from '../../../constants'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import playStoreIcon from '../../assets/images/playstore_icon.png';
import appStoreIcon from '../../assets/images/app_store_icon.png'
import  { assetTypeInfo, projectsSelfPermission } from '../';
import { trackActivity } from '../../../utils/mixpanel';
import config from '../../../config'
import RestrictedAccess from '../../../shared_elements/components/RestrictedAccess';
import { castleAirInstance } from '../../../shared_elements/components';
const ProjectDetail = ({params}) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const [assetType, setAssetType] = useState(null);
    const [assetId, setAssetId] = useState('');
    const [assetModal, setAssetModal] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [project, setProject] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [skeletonLoader, setSkeletonLoader] = useState(true);
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteIds, setDeleteIds] = useState(null);
    const [removeOprEng, setRemoveOprEng] = useState({modal:false});
    const [engineerAssigned, setAssignedEngineer] = useState({modal:false, selectedEngineer:[], assign_to_all:false, workOrderSlug:'', formSlug:'', engineers:[]})
    const [engineerAssignedLoader, setAssignedEngineerLoader] = useState(false)
    const [oprEngineer, setOprEngineer] = useState({modal:false, item:null});
    const [deleteWorkOrder,setDeleteWorkorder]=useState({modal:false,data:null})
    const[oprEngineers,setOprEngineers]= useState([])
    const[oprUsers,setOprUsers]= useState([])
    const [deleteEngModal, setDeleteEngModal] = useState({});

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        getProjectDetails('skeletonLoader');
        getOprEngineer();
        getOprUsers();
        trackActivity('Project Management', {
            event_type: 'Page Visited',
            page_title: 'Project Detail',
          });
    },[]);

    const getProjectDetails = (loaderType) => {
        if(loaderType === 'skeletonLoader'){setSkeletonLoader(true)}else{setLoading(true);}
        globalGetService(`technical/project/${params.project_slug}/`)
        .then(res => {
            if(checkApiStatus(res)){
                setProject(res.data.data);
            }
            else if(res.data.statusCode == 404){
                setProject({error:true})
            }
            if(loaderType === 'skeletonLoader'){setSkeletonLoader(false)}else{setLoading(false);}
        })
    }
    const assignUserToForm = () => {
        setAssignedEngineerLoader(true);
        let url ='';
        if(engineerAssigned?.formSlug){
          url = `technical/v2/project/${engineerAssigned.assetType === 2 ? 'engine-workorder':'workorder'}/${engineerAssigned.workOrderSlug}/form/${engineerAssigned.formSlug}/users/`
        }else{
          url = `technical/v2/project/${engineerAssigned.assetType === 2 ? 'engine-workorder':'workorder'}/${engineerAssigned.workOrderSlug}/users/`
        }
        globalPostService(url, {assign_to_all:engineerAssigned.assign_to_all,user_ids:engineerAssigned.selectedEngineer})
        .then(response => {
            setAssignedEngineerLoader(false);
            if(checkApiStatus(response)){
                setAssignedEngineer({modal:false, assign_to_all:false, selectedEngineer:null, workOrderSlug:'', formSlug:''})
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                getOprEngineer();
                getOprUsers();
                trackActivity('Project Management', {
                    event_type: 'Assign User Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    user_id:engineerAssigned.selectedEngineer.id,
                    response_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Assign User Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    response_msg: response.data.message
                  });
            }
        });
    }
    const onAssignEngineer = (data) => {
      setLoading(true);
      globalGetService(`technical/v2/project/${params.project_slug}/users/dropdown/`,{user_type: data?.formSlug?'form':'workorder'})
      .then(response => {
        if(checkApiStatus(response)){
          setAssignedEngineer({...data,engineers:response.data.data});
        }
        setLoading(false);
      })
    }
    const onSelectEngineers = (flag, user) => {
      if(flag){
          setAssignedEngineer({...engineerAssigned, selectedEngineer:[...engineerAssigned.selectedEngineer, user.user_id.id]});
      }else{
        setAssignedEngineer({...engineerAssigned, selectedEngineer:engineerAssigned.selectedEngineer.filter(userId => userId !== user.user_id.id)});
      }
    }
    const onRemoveProjectUser = () => {
        globalDeleteService(`technical/project/${params.project_slug}/remove-user/`, {user_id:deleteIds.id,project_role:deleteIds.project_role,project_form:0})
        .then(response => {
            if(checkApiStatus(response)){
                setDeleteModal(false);
                setDeleteIds(null);
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                trackActivity('Project Management', {
                    event_type: 'Remove Project User Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    user_id: deleteIds.id,
                    project_role: deleteIds.project_role ? deleteIds.project_role : '',
                    project_slug: params.project_slug ? params.project_slug : '',
                    success_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Remove Project User Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: engineerAssigned.workOrderSlug ? engineerAssigned.workOrderSlug : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    project_role: deleteIds.project_role ? deleteIds.project_role : '',
                    project_slug: params.project_slug ? params.project_slug : '',
                    error_msg: response.data.message
                  });
            }
        })
    }

    const onLaunchWorkOrder =(slug, type) => {
        globalGetService(`technical/${type === 2 ? 'engine-workorder':'workorder'}/${slug}/launch/`)
        .then(response => {
            if(checkApiStatus(response)){
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});
                getProjectDetails();
                trackActivity('Project Management', {
                    event_type: 'Launch Workorder Success',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: slug ? slug : '',
                    workorder_type: type ? type : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    success_msg: response.data.message
                  });
            }else{
                enqueueSnackbar(response.data.message, {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}});
                trackActivity('Project Management', {
                    event_type: 'Launch Workorder Failed',
                    page_title: 'Project Detail',
                    form_slug: engineerAssigned.formSlug ? engineerAssigned.formSlug : '',
                    workorder_slug: slug ? slug : '',
                    workorder_type: type ? type : '',
                    asset_type: engineerAssigned.assetType ? engineerAssigned.assetType : '',
                    error_msg: response.data.message
                  });
            }
        })
    }
    const onOprEngineer = (user, type) => {
        setLoading(true);
        globalPostService(`technical/project/${params.project_slug}/opr/${type}/`, {user_id:user.id})
        .then(response => {
            if(checkApiStatus(response)){
                setOprEngineer({modal:false, item:null});
                getProjectDetails('pageLoader');
                setRemoveOprEng({modal:false});
            }else{
                setLoading(false);
            }
        })
    }
    const onDeleteWorkOrder = () => {
        setLoading(true);
        globalDeleteService(`technical/project/${params.project_slug}/${deleteWorkOrder?.data?.asset?.type === 2 ? "engine-workorder":"workorder"}/${deleteWorkOrder?.data?.slug}/`)
        .then(response => {
            if(checkApiStatus(response)){
                setLoading(false);
                setDeleteWorkorder({modal:false});getProjectDetails()
                enqueueSnackbar(response.data.message, {variant:'success', anchorOrigin:{horizontal:'right', vertical:'top'}});

            }
        })
    }
    const getOprEngineer =()=>{
        setLoading(true);
        globalGetService(`technical/v2/project/${params.project_slug}/users/dropdown/`,{user_type:'assign_engineers'})
        .then(response => {
          if(checkApiStatus(response)){
            setOprEngineers(response.data.data);
          }
          setLoading(false);
        })
      }
    const getOprUsers=()=>{
        setLoading(true);
        globalGetService(`technical/v2/project/${params.project_slug}/users/dropdown/`,{user_type:'assign_leads'})
        .then(response => {
          if(checkApiStatus(response)){
            setOprUsers(response.data.data?.length ?response.data.data : []);
          }
          setLoading(false);
        })
      }
    const onDeleteUser = (deleteEngModal) => {
        if (! deleteEngModal?.formSlug ) {
            setLoading(true)
            globalDeleteService(`technical/v2/project/${deleteEngModal?.assetType == 2 ?'engine-workorder':'workorder'}/${deleteEngModal?.workOrderSlug}/users/${deleteEngModal?.data?.id}/`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        setDeleteEngModal({});
                        getProjectDetails();
                        getOprEngineer();
                        getOprUsers();
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        setLoading(false);
                    }
                })
        }
        else {
            setLoading(true)
            globalDeleteService(`technical/v2/project/${deleteEngModal?.assetType == 2 ?'engine-workorder':'workorder'}/${deleteEngModal?.workOrderSlug}/form/${deleteEngModal?.formSlug}/users/${deleteEngModal?.data?.id}/`)
                .then(response => {
                    if (checkApiStatus(response)) {
                        enqueueSnackbar(response.data.message, { variant: 'success', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        setDeleteEngModal({});
                        getProjectDetails();
                        getOprEngineer();
                        getOprUsers();
                    }
                    else {
                        enqueueSnackbar(response.data.message, { variant: 'error', anchorOrigin: { horizontal: 'right', vertical: 'top' } });
                        setLoading(false);
                    }
                })
        }
    }
    const backUrl = JSON.parse(localStorage.getItem('lisitng_url'))
    console.log('project',project);
    return(
        <section className='projects-management-apps projects-management-section'>
            <div className='project-view-sec'>
              { project.error ?  null :  <ProjectDetailTab tabIndex={'inspection'} handleTabChange={() => console.log('')} />}
                { skeletonLoader ? <ProjectViewsSkeleton />:
                project.error ?
                <RestrictedAccess />:
                    <Fragment>
                        <div className='project-view-header'>
                            <Grid container alignItems='center' spacing={1}>
                                <Grid item xs={12} md={7}>
                                    <ul className='list-inline flex-centered'>
                                        <li className='list-inline-item'>
                                            <ArrowBackIcon onClick={() => browserHistory.push(backUrl? backUrl : '/technical/projects?project_status=all')} color="primary" fontSize='small' />
                                        </li>
                                        <li className='list-inline-item'>
                                            <span style={{ fontSize: "11px", fontFamily: 'Conv_IBMPlexSans-Medium' }}>{"Project Number : " + project.project_number}
                                                <span style={{ marginLeft: "12px" }} className='project-period'>{`${moment(project.start_date).isValid() ? moment(project.start_date).format(displayDateFormatShort) :  'TBD'} to ${moment(project.expected_end_date).isValid() ? moment(project.expected_end_date).format(displayDateFormatShort) : 'TBD'}`}</span>
                                            </span>
                                            <h4>{project.name}
                                                {project.status && project.status.label ?
                                                    <span style={{ marginLeft: "20px" }} className={`project-status ${project.status.label.toLowerCase()}`}>{project.status.label}</span> : null
                                                }</h4>
                                        </li>
                                    </ul>
                                </Grid>
                                <Grid item xs={12} md={5}>
                                    <ul className='list-inline project-cta'>
                                        { onCheckPermission('project_management', 'workorder','C') && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?
                                            <li className='list-inline-item' style={{marginTop:'5px'}}>
                                                <WorkOrderDropdown
                                                    toggleModalFn={(assetType) => {setAssetModal(true); setAssetType(assetType);setAssetId('')}}
                                                />
                                            </li>:null
                                        }
                                    </ul>
                                    {(() => {
                                        let oprFlg = project && Object.keys(project).length && project.opr_required;
                                        let viewPermission = oprUsers?.map(item => item?.user_id)?.find(item=> item?.id == getLocalStorageInfo()?.user?.id)?.id
                                        let engineerPermission = oprEngineers?.map(item => item?.user_id)?.find(item=> item?.id == getLocalStorageInfo()?.user?.id)?.id
                                        if (oprFlg && (viewPermission|| engineerPermission)) {
                                            return (
                                                <Paper style={{padding:"5px"}} className='flex-centered form-label'>
                                                   <div style={{display:"inline-flex"}}> <h4>OPR<Tooltip title='Operator Performance Rating' arrow><InfoIcon color='primary' style={{ fontSize: '15px' }} /></Tooltip>   </h4>
                                                        {project?.opr_user?.name ? <span className='assigned-engineer' style={{fontSize:"12px", marginLeft:"15px"}}>Assigned User: {project.opr_user.name}</span> : null}
                                                    </div>
                                                    <ul className='list-inline' style={{ marginLeft: 'auto' }}>
                                                        {project?.opr_user?.id ?
                                                            <>
                                                            { viewPermission ?
                                                                <li className='list-inline-item'>
                                                                    <Tooltip title='Change User' arrow>
                                                                        <EditIcon onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOprEngineer({ modal: true, item: null }) }} color="primary" fontSize='small' style={{ cursor: 'pointer' }} />
                                                                    </Tooltip>
                                                                </li>: null
                                                                }
                                                                 { viewPermission ?
                                                                <li className='list-inline-item' onClick={() => setRemoveOprEng({ modal: true })}>
                                                                    <Tooltip title='Change User' arrow>
                                                                        <Tooltip title='Remove User' arrow><DeleteOutlineIcon color="secondary" fontSize='small' style={{ cursor: 'pointer' }} /></Tooltip>
                                                                    </Tooltip>
                                                                </li> : null}
                                                                { (viewPermission || engineerPermission)  ?
                                                                <li onClick={() => browserHistory.push(`/technical/project/view/${params.project_slug}/project-opr-form`)} className='list-inline-item'>
                                                                    <ArrowForwardIosIcon color="primary" fontSize='small' style={{ cursor: 'pointer' }} />
                                                                </li>
                                                                : null}
                                                            </> :
                                                            viewPermission ?
                                                            <li className='list-inline-item'>
                                                                <Button onClick={(e) => { e.preventDefault(); e.stopPropagation(); setOprEngineer({ modal: true, item: null }) }} size='small' variant='outlined' color='primary'>Assign User</Button>
                                                            </li>: null
                                                        }
                                                    </ul>
                                                </Paper>
                                            )
                                        }
                                    })()}
                                </Grid>
                            </Grid>
                        </div>
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                { project.project_form && project.project_form === 1 ?
                                    <FormT012 project={project} />:null
                                }
                                { project.work_orders && project.work_orders.length ? project.work_orders?.map((workOrder,index) =>
                                    <Accordion key={workOrder.id} expanded={expanded === `workOrder-${workOrder.id}`} onChange={handleChange(`workOrder-${workOrder.id}`)}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id={`workOrder-${workOrder.id}`}
                                        >
                                            <WorkOrderHd
                                                setDeleteEngModal={setDeleteEngModal}
                                                permission={project?.permission||{}}
                                                project={project}
                                                asset={workOrder.asset}
                                                workOrder={workOrder}
                                                onEditWorkOrder={() => { setAssetId(workOrder.slug); setAssetModal(true); setAssetType(workOrder.asset.type);}}
                                                onDeleteWorkOrder={()=>setDeleteWorkorder({modal:true,data:workOrder})}
                                                onAssignEngineer={() => onAssignEngineer({assetType:workOrder.asset.type,modal:true, selectedEngineer:workOrder?.leads?.length ? workOrder.leads.map(eng => eng.id):[], assign_to_all:false, workOrderSlug:workOrder.slug})}
                                            />
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <div className="workOrder-forms">
                                                {(() => {
                                                  let formsArray = [];
                                                  if(onCheckPermission('project_management','form','R')){
                                                    if(project?.formPermission?.read?.value === 2){
                                                      formsArray = workOrder.forms;
                                                    }else{
                                                        let loggedUserId = getLocalStorageInfo().user.id;
                                                        formsArray = workOrder.forms.filter(form => form.engineers.map(engineer=>engineer.id).includes(loggedUserId))
                                                    }
                                                  }
                                                  return(
                                                    <>
                                                    { formsArray.length && workOrder?.status?.id === 1 && onCheckPermission('project_management', 'workorder','U') && projectsSelfPermission('workorder','U', workOrder, project?.permission?.update?.value||null) && (project?.status?.id && ![0,7,8].includes(project.status.id)) ?
                                                        <div style={{margin:'10px 10px 10px 0', float: 'right'}}>
                                                            <Button onClick={() => onLaunchWorkOrder(workOrder.slug, workOrder.asset.type)} variant="contained" color="primary" size="small">Launch</Button>
                                                        </div>
                                                        :null
                                                    }
                                                    <div>
                                                        <Table>
                                                            { formsArray.length ?
                                                                <TableBody>
                                                                    { formsArray.map((form, formIndex) =>
                                                                        <FormList
                                                                            setDeleteEngModal={setDeleteEngModal}
                                                                            project={project}
                                                                            workOrderSlug={workOrder.slug}
                                                                            asset={workOrder.asset}
                                                                            key={formIndex}
                                                                            form={form}
                                                                            onAssignEngineer={() => onAssignEngineer({assetType:workOrder.asset.type,modal:true, selectedEngineer:form.engineers.map(eng => eng.id), assign_to_all:false, workOrderSlug:workOrder.slug, formSlug:form.slug})}
                                                                            onRedirection = {(e) => {
                                                                              workOrder.status && workOrder.status.id === 1 && !(castleAirInstance && form.id==3 ) ? enqueueSnackbar('First Launch the WorkOrder', {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}}) : form?.engineers?.length ? browserHistory.push(`/${assetTypeInfo[workOrder.asset.type].key}/${workOrder.asset.slug}/technical/${workOrder.slug}/forms/${form.slug}/${form.name}`): enqueueSnackbar('Assign Form User(s)', {variant:'error', anchorOrigin:{horizontal:'right', vertical:'top'}}) }
                                                                            }
                                                                        />
                                                                    )}
                                                                </TableBody>:
                                                                <TableBody>
                                                                    <TableRow>
                                                                        <TableCell colSpan={3} style={{textAlign:'center'}}>{workOrder.forms ? 'No Form(s) Assigned':'No Form(s) Added'}</TableCell>
                                                                    </TableRow>
                                                                </TableBody>
                                                            }
                                                        </Table>
                                                    </div>
                                                    </>
                                                  )
                                                })()}
                                            </div>
                                        </AccordionDetails>
                                    </Accordion>
                                ):<p style={{textAlign:'center'}}>No WorkOrder Added Yet</p>}
                            </Grid>
                        </Grid>
                    </Fragment>
                }
            </div>
            {assetType && [3,4,5].includes(assetType) && assetModal ?
                <ALPCRU
                    modal={assetModal}
                    toggleModalFn={() => {setAssetId('');setAssetModal(false); setAssetType(null);}}
                    assetType={assetType}
                    project={project}
                    id={assetId}
                    getResponseBack={() => {setAssetType('');setAssetId('');setAssetModal(false);getProjectDetails()}}
                />:null
            }
            {assetType && assetType === 1 && assetModal ?
                <AircraftCRU
                    modal={assetModal}
                    toggleModalFn={() => {setAssetId(''); setAssetModal(false); setAssetType(null);}}
                    assetType={assetType}
                    project={project}
                    id={assetId}
                    getResponseBack={() => {setAssetType('');setAssetId('');setAssetModal(false);getProjectDetails()}}
                />:null
            }
            { assetType && assetType === 2 && assetModal ?
                <AddEngineWorkOrder
                    modal={assetModal}
                    toggleModalFn={() => {setAssetId(''); setAssetModal(false); setAssetType(null);}}
                    getResponseBack={() => {setAssetType('');setAssetId('');setAssetModal(false);getProjectDetails()}}
                />:null
            }
            {deleteModal ?
                <DeletePopUp
                    modal={deleteModal}
                    toggleModalFn={() => setDeleteModal(false)}
                    title="Remove User"
                    content={<h4>Are you sure you want to remove?</h4>}
                    deleteRecordFn={() => onRemoveProjectUser()}
                    confirmText="Remove"
                />:null
            }
            { engineerAssigned.modal ?
                <AssignEngineer
                    users={project.engineers ? project.engineers:[]}
                    engineerAssigned={engineerAssigned}
                    toggleModalFn={() => setAssignedEngineer({assetType:'',modal:false, selectedEngineer:[], engineers:[], assign_to_all:false, workOrderSlug:'', formSlug:''})}
                    selectProjectEngineerFn={(flag, user) =>onSelectEngineers(flag, user)}
                    onSelectAllForms ={(flag) => setAssignedEngineer({...engineerAssigned, assign_to_all:flag})}
                    assignUserToForm={assignUserToForm}
                    engineerAssignedLoader={engineerAssignedLoader}
                />:null
            }
            { oprEngineer.modal ?
                <AssignOprEngineer
                    type='opr'
                    oprEngineersList={oprEngineers.map(item=> item?.user_id)}
                    modal={oprEngineer.modal}
                    oprEngineer={oprEngineer}
                    toggleModalFn={()=>setOprEngineer({modal:false, item:null})}
                    getResponseBack={() => console.log('Hi')}
                    engineers={project?.engineers || []}
                    onOprEngineer={onOprEngineer}
                    project={project}
                />:null
            }
            {removeOprEng.modal ?
                <DeletePopUp
                    modal={removeOprEng.modal}
                    toggleModalFn={() => setRemoveOprEng({modal:false})}
                    title="Remove User"
                    content={<h4>Are you sure you want to remove?</h4>}
                    deleteRecordFn={() => onOprEngineer(project.opr_user, 'remove')}
                    confirmText="Remove"
                />:null
            }
            {deleteWorkOrder?.modal ?
              <DeletePopUp
                  modal={true}
                  toggleModalFn={()=>setDeleteWorkorder({modal:false})}
                  title="Delete WorkOrder "
                  content={<h4>Are you sure you want to remove?</h4>}
                  deleteRecordFn={()=>onDeleteWorkOrder()}
                  confirmText='Delete'
              />
              : null
            }
            {
                deleteEngModal?.modal ?
                <DeletePopUp
                modal={deleteEngModal.modal}
                toggleModalFn={() => setDeleteEngModal({modal:false, data:null})}
                title={deleteEngModal?.type =='lead'? 'Remove Lead': `Remove Form User`}
                content={<h4>Are you sure you want to remove?</h4>}
                deleteRecordFn={()=>onDeleteUser(deleteEngModal)} />
                : null
            }
            { isLoading ? <PageLoader /> : null}
        </section>
    )
}
export default ProjectDetail;
